import { baseApi } from '../base';
import { categoryRequest, categoryResponce, mccResponce } from './type';

const categoryApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getCategory: build.query<categoryResponce[], any>({
      query: () => '/category',
      providesTags: ['category'],
    }),
    createCategory: build.mutation<any, categoryRequest>({
      query: (data) => ({
        url: `/category`,
        method: 'POST',
        body: data,
      }),
    }),
    statusToggel: build.mutation({
      query: (id) => ({
        method: 'PUT',
        url: `/category/${id}`,
      }),
      invalidatesTags: ['category'],
    }),
    deleteCategory: build.mutation({
      query: (id) => ({
        method: 'DELETE',
        url: `/category/${id}`,
      }),
    }),
    getMCCData: build.query<mccResponce, any>({
      query: () => 'mcc'
    }),
  }),
});

export const {
  useGetCategoryQuery,
  useCreateCategoryMutation,
  useStatusToggelMutation,
  useDeleteCategoryMutation,
  useGetMCCDataQuery,
} = categoryApi;
