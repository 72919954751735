import { baseApi } from '../base';
import { createRefund } from './types';
const refundApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        refundCustomer: build.mutation<any, createRefund>({
            query: ({ id, ...data }) => {
                return ({
                    method: "POST",
                    url: `/invoice/${id}/refund`,
                    body: data
                })
            },
            invalidatesTags: ["customerinvoice"]
        }),
    }),
});


export const {
    useRefundCustomerMutation,
} = refundApi



