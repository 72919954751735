import { baseApi } from '../base';
import { cardResponce, invoiceResponce } from './type';

const Invoice = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getInvoice: build.query<invoiceResponce[], any>({
      query: () => '/invoice',
      providesTags: ['invoice'],
    }),
    getCard: build.query<cardResponce[], any>({
      query: () => '/card',
      providesTags: ['card','subscription'],
    }),
    createMerchantCard: build.mutation<any, any>({
      query:(data) => {  
          return ({
              method:"POST",
              url:`/card/${data.token}`,
          })
      },
      invalidatesTags: ['card'],
  }),
    
  }),
});

export const { useGetInvoiceQuery, useGetCardQuery,useCreateMerchantCardMutation } = Invoice;
