// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, Alert, Link } from '@mui/material';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { HEADER, NAVBAR } from '../../../config';
// components
import Logo from '../../../components/Logo';
import Iconify from '../../../components/Iconify';
import { IconButtonAnimate } from '../../../components/animate';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import { Link as RouterLink } from 'react-router-dom';
import {
  useGetMerchantQuery,
  useGetMerchantResponseQuery,
  useGetPlanQuery,
  useGetstripConnectQuery,
} from 'src/services';

import useLocales from 'src/hooks/useLocales';
import { useEffect, useState } from 'react';

type RootStyleProps = {
  isCollapse: boolean;
  isOffset: boolean;
  verticalLayout: boolean;
};

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) =>
    prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})<RootStyleProps>(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

// ----------------------------------------------------------------------

type Props = {
  onOpenSidebar: VoidFunction;
  isCollapse?: boolean;
  verticalLayout?: boolean;
};

export default function DashboardHeader({
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
}: Props) {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
  const { isFetching, data:current } = useGetstripConnectQuery({});
  const { data: planquery } = useGetPlanQuery({
    limit:5,
    offset:0
  });
  const { data: sub } = useGetMerchantResponseQuery({});

  const bankDetail = current?.bank;

  const { translate } = useLocales();

  const smUp = useResponsive('up', 'sm');

  const isDesktop = useResponsive('up', 'lg');

  const [time, setTime] = useState<boolean>(true);

  const { data } = useGetMerchantQuery({}, { pollingInterval: time ? 3000 : undefined });

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (data?.status === 'active' && !planquery?.length) {
      setOpen(true);
      setTime(false);
    }
  }, [data]);

  useEffect(() => {
    setOpen(false);
  }, [planquery]);

  return (
    <RootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 5 },
        }}
      >
        {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}

        {!isDesktop && (
          <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}
        <Searchbar />
        <Box sx={{ flexGrow: 1 }} />
        {bankDetail?.length === 1 ? (
          <>
            {sub?.merchant_plan == null ? (
              <>
                {isFetching ? (
                  ''
                ) : (
                  <>
                    {smUp && <>
                      <Alert
                        severity="error"
                        sx={{ display: 'flex', justifyContent: 'center', width: '750px' }}
                      >
                        <strong> {translate('purchasePlan')} </strong>
                        <Link
                          to={`/dashboard/setting/list?tab=${translate('myPlan')}`}
                          sx={{ color: 'blue' }}
                          component={RouterLink}
                        >
                          {translate('clickHere')}
                        </Link>
                      </Alert>
                    </>
                    }
                    {!smUp && <>
                      <Alert
                        severity="error"
                        sx={{ display: 'flex', justifyContent: 'center', fontSize: 12 }}
                      >
                        <strong> {translate('purchasePlan')} </strong>
                        <Link
                          to={`/dashboard/setting/list?tab=${translate('myPlan')}`}
                          sx={{ color: 'blue' }}
                          component={RouterLink}
                        >
                          {translate('clickHere')}
                        </Link>
                      </Alert>
                    </>
                    }

                  </>
                )}
              </>
            ) : (
              <>
                {open ? (
                  <>
                    {smUp && <>
                      <Alert
                        severity="error"
                        sx={{ display: 'flex', justifyContent: 'center', width: '750px' }}
                      >
                        <strong>{translate('firstPlane')}</strong>
                        <Link to="/dashboard/plans/new" sx={{ color: 'blue' }} component={RouterLink}>
                          {translate('clickHere')}
                        </Link>
                      </Alert>
                    </>
                    }
                    {!smUp && <>
                      <Alert
                        // icon={false}
                        severity="error"
                        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 14, mt: 1 }}
                      >
                        <strong>{translate('firstPlane')}</strong>
                        <Link to="/dashboard/plans/new" sx={{ color: 'blue' }} component={RouterLink}>
                          {translate('clickHere')}
                        </Link>
                      </Alert>
                    </>
                    }
                  </>
                ) : (
                  ''
                )}
              </>
            )}
          </>
        ) : (
          <>
            {isFetching ? (
              ' '
            ) : (
              <>
                {smUp && (
                  <>
                    <Alert
                      severity="error"
                      sx={{ display: 'flex', justifyContent: 'center', width: '750px' }}
                    >
                      <strong>{translate('firstAddBank')}</strong>
                      <Link
                        to={`/dashboard/setting/list?tab=${translate('bankDetails')}`}
                        sx={{ color: 'blue' }}
                        component={RouterLink}
                      >
                        {translate('clickToAddBank')}
                      </Link>
                    </Alert>
                  </>
                )}
                {!smUp && (
                  <>
                    <Alert
                      icon={false}
                      severity="error"
                      color="error"
                      sx={{ display: 'flex', justifyContent: 'center', fontSize: 12 }}
                    >
                      <strong>{translate('firstAddBank')}</strong>
                      <Link
                        to={`/dashboard/setting/list?tab=${translate('bankDetails')}`}
                        sx={{ color: 'blue' }}
                        component={RouterLink}
                      >
                        {translate('clickToAddBank')}
                      </Link>
                    </Alert>
                  </>
                )}
              </>
            )}
          </>
        )}
        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <AccountPopover />
        </Stack>
      </Toolbar>
    </RootStyle>
  );
}
