import { baseApi } from '../base';
import {
  fetchPlanByCustomerId,
  planPhaseResponse,
  planRequest,
  planResponce
} from './type';

type planTypeRequest = {
  limit?: number;
  offset?: number;
};

const planApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getPlan: build.query<planResponce[], planTypeRequest>({
      query: ({ limit, offset }) => `/plan?limit=${limit}&offset=${offset}`,
      providesTags: ['plan'],
    }),
    getPlanById: build.query<planResponce, any>({
      query: (id) => `/plan/${id}`,
      providesTags: ['plan'],
    }),
    getActivePlan: build.query<planResponce[], any>({
      query: () => `/plan?status=active`,
    }),
    createPlan: build.mutation<any, planRequest>({
      query: (data) => ({
        method: 'POST',
        url: `/plan`,
        body: data,
      }),
      invalidatesTags: ['plan'],
    }),
    statustoggel: build.mutation<any, any>({
      query: (id) => ({
        url: `/plan/${id}`,
        method: 'PUT',
      }),
      invalidatesTags: ['plan'],
    }),
    deletePlan: build.mutation<any, any>({
      query: (id) => ({
        url: `plan/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['plan'],
    }),
    planPhase: build.mutation<planPhaseResponse[], any>({
      query: ({ id, data }) => ({
        method: 'POST',
        url: `/plan/${id}/phase`,
        body: data,
      }),
      invalidatesTags: ['plan'],
    }),
    planUpdate: build.mutation<any, any>({
      query: ({ id, data }) => ({
        method: 'GET',
        url: `/plan/${id}`,
        body: data,
      }),
      invalidatesTags: ['plan'],
    }),
    fetchPlanByCustomerId: build.query<fetchPlanByCustomerId[], any>({
      query: ({ id }) => ({
        method: 'GET',
        url: `customer/${id}/plan`,
        providesTags: ['customerplanstatus'],
      }),
    }),
  }),
});

export const {
  useGetPlanQuery,
  useGetPlanByIdQuery,
  useCreatePlanMutation,
  useStatustoggelMutation,
  useDeletePlanMutation,
  usePlanPhaseMutation,
  usePlanUpdateMutation,
  useGetActivePlanQuery,
  useFetchPlanByCustomerIdQuery,
} = planApi;
