import { baseApi } from "../base";
import {  couponResponce } from "./type";

type GetCouponRequest = {
    limit?: number;
    offset?: number;
}
const couponApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getCoupon: build.query<couponResponce[], GetCouponRequest>({
            query: ({ limit, offset }) => `/coupon?limit=${limit}&offset=${offset}`,
            providesTags: ["coupon"],
        }),
        createCoupon: build.mutation({
            query:(data) => {
                return ({
                    method:"POST",
                    url:"/coupon",
                    body:data
                })
            }
        }),
        deleteCoupon: build.mutation({
            query:(id) => {
                return ({
                    method:"DELETE",
                    url:`/coupon/${id}`,
                })
            }
        }),
        getCouponApply: build.mutation<any, string>({
            query: (data) => ({
              method: 'GET',
              url: `/coupon/${data}/apply`,
            }),
          }),
    }),
    
})

export const {
    useGetCouponQuery,
    useCreateCouponMutation,
    useDeleteCouponMutation,
    useGetCouponApplyMutation,
} = couponApi