import { baseApi } from '../base';
import { amountRespnse, payoutResponse, transactionRespnse } from './types';


const payoutApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getPayout: build.query<payoutResponse, any>({
            query: () => `/payout`
        }),
        getTransaction: build.query<transactionRespnse, any>({
            query: () => `/transaction`
        }),
        getAmount:build.query<amountRespnse,any>({
        query:() => `/balance`
        }),
    })
});

export const {
    useGetPayoutQuery,
    useGetTransactionQuery,
    useGetAmountQuery,
} = payoutApi;
