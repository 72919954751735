import { createSlice } from "@reduxjs/toolkit"

const userDetailsSlice: any = createSlice({
    name: 'userDetails',
    initialState: {
        details: []
    },
    reducers: {
        updateUserDetails(state: any, payload: any) {
            state.details = payload?.payload.details
            let user = state.details?.merchant_bussinesses?.length > 0 ? JSON.stringify(state.details?.merchant_bussinesses) : null
            let userPageDetails = state?.details?.merchant_page?.mid > 0 ? JSON.stringify(state.details?.merchant_page) : null
            if (user) {
                window.localStorage.setItem('userDetails', user)
            }
            if (userPageDetails) {
                window.localStorage.setItem('userPageDetails', userPageDetails)
            }
        },

    }
})
export default userDetailsSlice.reducer;
export const { updateUserDetails } = userDetailsSlice.actions