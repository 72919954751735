import { baseApi } from '../base';
import { authLoginResponse } from './type';
const authApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAuthLogin: build.mutation<authLoginResponse, any>({
      query: (data) => {
        return {
          method: 'GET',
          url: `/signin/${data}`,
        };
      },
      invalidatesTags: ['plan'],
    }),
    registerUser: build.mutation<any, any>({
      query: (data) => {
        return {
          method: 'POST',
          url: `/signup`,
          body: data,
        };
      },
    }),
  }),
});

export const { useGetAuthLoginMutation, useRegisterUserMutation } = authApi;
