import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import {
  Box,
  Divider,
  Typography,
  MenuItem,
  AvatarProps, Link, Button
} from '@mui/material';
// routes
import { PATH_DASHBOARD, PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import MyAvatar from '../../../components/MyAvatar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import { useGetAuthLoginMutation } from 'src/services';
import { getAuth } from 'firebase/auth';
import { Stack } from '@mui/system';
import Iconify from 'src/components/Iconify';
import useResponsive from 'src/hooks/useResponsive';
import { CustomerURL } from 'src/config';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    linkTo: '/',
  },
  {
    label: 'Profile',
    linkTo: PATH_DASHBOARD.user.profile,
  },
  {
    label: 'Settings',
    linkTo: PATH_DASHBOARD.user.account,
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover({ ...other }: AvatarProps) {
  const navigate = useNavigate();

  const { user, logout } = useAuth();

  const smUp = useResponsive('up', 'sm');

  const isMountedRef = useIsMountedRef();
  const [loginAuth] = useGetAuthLoginMutation();
  const { currentUser } = getAuth();

  const email = currentUser?.email;
  const [logo, setLogo] = useState<any>('');
  const [value, setvalue] = useState<any>('');
  const [color, setColor] = useState<any>('');


  useEffect(() => {
    loginAuth(email)
      .unwrap()
      .then((res) => {
        const slug = res.merchant_page.pageSlug;
        const logo = res.merchant_page.logo;
        setColor(res.merchant_page.themeColor);
        setLogo(logo);
        setvalue(slug);
      });
  }, []);

  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    try {
      await logout();
      localStorage.clear();
      navigate(PATH_AUTH.login, { replace: true });
      window.location.reload();
      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  return (
    <>
      {/* <Tooltip title="GoLive"> */}
      <Link
        href={`${CustomerURL}/${value}`}
        target="_blank"
        sx={{
          textDecoration: 'none',
          textAlign: 'center',
          '&:hover': {
            textDecoration: 'none',
          },
          direction: 'row',
          alignItems: 'center',
        }}
        color={color}
      >

        {smUp &&
          <>
            <Stack
              direction='row'
              display='flex'
              justifyContent='center'
              alignItems='center'
            >
              {/* <Avatar
                src={logo}
                alt={user?.displayName}
                {...other}
                sx={{ border: `2px solid ${color}` }}
              /> */}
              <Button
                sx={{
                  fontWeight: 600,
                  fontSize: '1rem',
                  color: `${color}`
                }}
              >
                Go Live
                <Iconify
                  color={color}
                  fontSize={20}
                  icon={'mdi:external-link'}
                />
              </Button>
            </Stack>
          </>
        }

      </Link>

      {/* </Tooltip> */}
      <IconButtonAnimate
        id="iconButton"
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <MyAvatar id="account" />
      </IconButtonAnimate>

      <MenuPopover
        id="menu"
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {/* <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem
              id={option.label}
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={handleClose}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack> */}

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem id="logout" onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
