import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { APP_ENV } from 'src/config';
import { RootState } from 'src/redux/store';

export const baseApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: APP_ENV !== 'PROD' ? 'https://dev-api.subscription.app/merchant/api/v1' : 'https://api.subscription.app/merchant/api/v1',
    prepareHeaders: (headers, { getState }) => {
      const { token }: any = (getState() as RootState).user;

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: () => ({}),
  tagTypes: [
    'category',
    'merchant',
    'tax',
    'plan',
    'coupon',
    'customer',
    'merchant-plan',
    'connect',
    'dashboard',
    'invoice',
    'card',
    'subscription',
    'signup',
    'customerplan',
    'customerinvoice',
    'customerplanstatus',
  ],
});
