import { baseApi } from '../base';
import { InvoiceResponse, merchantResponse, merchantSubscriptionRequest } from './type';

const merchantBuisness = baseApi.injectEndpoints({
  endpoints: (build) => ({
    buisnessSubs: build.mutation<any, merchantSubscriptionRequest>({
      query: (data) => ({
        method: 'POST',
        url: '/merchant-subscription',
        body: data,
      }),
      invalidatesTags: ['subscription'],
    }),
    getMerchantResponse: build.query<merchantResponse, any>({
      query: () => '/subscription',
      providesTags: ['subscription'],
    }),
    pauseCustomerPlan: build.mutation({
      query: (data) => {
        return ({
          method: "PATCH",
          url: `/subscription/${data.merchantid}/pause`,
          body: data.resumeAt
        })
      },
      invalidatesTags: ["customerplanstatus"]
    }),
    resumeCustomerPlan: build.mutation({
      query: (resumeId) => {
        return ({
          method: "PATCH",
          url: `/subscription/${resumeId}/resume`,
        })
      },
      invalidatesTags: ["customerplanstatus"]
    }),
    cancelCustomerPlan: build.mutation({
      query: ({ id, data }) => {
        return ({
          method: "DELETE",
          url: `/subscription/${id}/customer`,
          body: data.comment
        })
      },
      invalidatesTags: ["customerplanstatus"]
    }),
    customerPlanInvoice: build.query<InvoiceResponse, any>({
      query: (id) => `/customer/${id}/invoice`,
      providesTags:['customerinvoice']
    }),
  }),
});

export const {
  useBuisnessSubsMutation,
  useGetMerchantResponseQuery,
  usePauseCustomerPlanMutation,
  useCancelCustomerPlanMutation,
  useCustomerPlanInvoiceQuery,
  useResumeCustomerPlanMutation,
} = merchantBuisness;
