import { useMemo, ReactNode } from 'react';
// @mui
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material/styles';
import palette from '../palette';
import typography from '../typography';
import breakpoints from '../breakpoints';
import componentsOverride from '../overrides';
import shadows, { customShadows } from '../shadows';
import shadeColor from 'src/utils/color';

type Props = {
  children: ReactNode;
  themeColor?: string|null;
};

export default function DynamicThemeProvider({ children, themeColor }: Props) {

  const isLight = 'light';

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette: {
        ...palette.light,
        primary: {
          ...palette.light.primary,
          main: themeColor || palette.light.primary.main,
          light: themeColor ? shadeColor(themeColor, .6) : palette.light.primary.light,
          dark: themeColor ? shadeColor(themeColor, 1.4) : palette.light.primary.dark
        }
      },
      typography,
      breakpoints,
      shape: { borderRadius: 8 },
      shadows: isLight ? shadows.light : shadows.dark,
      customShadows: isLight ? customShadows.light : customShadows.dark,
    }),
    [isLight, themeColor]
  );

  const theme = createTheme(themeOptions);

  theme.components = componentsOverride(theme);

  return (
    <ThemeProvider theme={(outerTheme) => ({
      ...outerTheme,
      ...theme
    })}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}