import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, Alert, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { PATH_AUTH, PATH_BUISNESS, PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { useGetAuthLoginMutation } from 'src/services';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { updateUserDetails } from 'src/redux/slices/userDetails';
import { onAuthStateChanged, sendEmailVerification } from 'firebase/auth';
import { updateToken } from 'src/redux/slices/users';
import { AUTH } from 'src/contexts/FirebaseContext';
import useLocales from 'src/hooks/useLocales';

// ----------------------------------------------------------------------

type FormValuesProps = {
  email: string;
  password: string;
  remember: boolean;
  afterSubmit?: string;
};

export default function LoginForm() {
  const { login, logout } = useAuth();

  const { translate } = useLocales();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  //login Auth api
  const [loginAuth] = useGetAuthLoginMutation();

  const isMountedRef = useIsMountedRef();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email(translate('valideEmailAddress')).required(translate('emailRequired')),
    password: Yup.string().required(translate('passwordIsRequired')),
  });

  const defaultValues = {
    email: '',
    password: '',
    remember: true,
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    watch,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const { email } = watch();

  const onSubmit = async (data: FormValuesProps) => {
    try {
      const authData = await loginAuth(data.email).unwrap()
      if (authData.status === 'suspended') {
        setError('afterSubmit', { message: "You are Suspended Contact To info@subscription.app" });
        return;
      }
      dispatch(
        updateUserDetails({
          details: authData,
        })
      );
      await login(data.email, data.password).then((res) => {
        if (res.user.emailVerified) {
          enqueueSnackbar(translate('loginSuccess'));
        }
      });
    } catch (error) {
      if (isMountedRef.current) {
        if (error?.message) {
          setError('afterSubmit', { ...error, message: error.message });
        } else {
          setError('afterSubmit', { ...error, message: error.data.error });
        }
      }
    }
  };

  useEffect(() => {
    onAuthStateChanged(AUTH, async (user) => {

      if (user) {
        const token = await user.getIdToken();
        const personalData = window.localStorage.getItem('userDetails');
        const pageData = window.localStorage.getItem('userPageDetails');
        console.log(personalData);


        if (token && user.emailVerified) {
          if (personalData) {
            const data = JSON.parse(personalData);
            if (data?.length <= 0) {
              navigate(PATH_BUISNESS.business.details, { replace: false });
            } else {
              if (pageData) {
                const pageDetails = JSON.parse(pageData);
                if (!pageDetails) {
                  navigate(PATH_BUISNESS.business.pagesetup(data?.[0]?.id), { replace: false });
                } else {
                  navigate(PATH_DASHBOARD.general.app);
                }
              } else {
                navigate(PATH_BUISNESS.business.pagesetup(data?.[0]?.id), { replace: false });
              }
            }
          } else {
            navigate(PATH_BUISNESS.business.details, { replace: false });
          }
        } else {
          if (token) {
            // sendEmailVerification(user);
            handleLogout();
          }
        }
      }
    });
  }, []);

  const handleLogout = async () => {
    try {
      localStorage.clear();
      dispatch(
        updateToken({
          token: null
        })
      )
      await logout();
      setError('afterSubmit', { message: `Please verify your email ${email} and try to login` });
      navigate(PATH_AUTH.login, { replace: false });
      // window.location.reload()
    } catch (error) {
      console.error(error);
      // enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && (
          <Alert id="message" severity="error">
            {errors.afterSubmit.message}
          </Alert>
        )}

        <RHFTextField id="name" name="email" label={translate('email')} />

        <RHFTextField
          id="password"
          name="password"
          label={translate('password')}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton id="eye" onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        {/* <RHFCheckbox name="remember" label="Remember me" /> */}
        <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
          {translate('forgotPassword')}
        </Link>
      </Stack>

      <LoadingButton
        id="submit"
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        {translate('login')}
      </LoadingButton>
    </FormProvider>
  );
}
