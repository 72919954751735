// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
// import Label from '../../../components/Label';
// import Iconify from '../../../components/Iconify';
import SvgIconStyle from '../../../components/SvgIconStyle';
import Iconify from 'src/components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
  coupon: getIcon('ic_coupon'),
  setting: getIcon('ic_setting'),
};

const navConfig = [
  // DASHBOARD
  {
    subheader: 'app',
    items: [
      { title: 'dashboard', path: '/dashboard/app', icon: ICONS.dashboard },
      {
        title: 'plans',
        path: PATH_DASHBOARD.plans.root,
        icon: ICONS.invoice
      },
      // { title: 'Coupon', path: PATH_DASHBOARD.coupon.list, icon: ICONS.coupon }, hide we will use it late on
      { title: 'customer', path: PATH_DASHBOARD.customer.root, icon: ICONS.user },
      { title: 'payout', path: PATH_DASHBOARD.payout.list, icon: <Iconify icon={'vaadin:invoice'}></Iconify> },
      { title: 'setting', path: PATH_DASHBOARD.setting.root, icon: ICONS.setting },
      // {
      //   title: 'invoice',
      //   path: PATH_DASHBOARD.invoice.root,
      //   icon: ICONS.invoice,
      //   children: [
      //     { title: 'list', path: PATH_DASHBOARD.invoice.list },
      //     { title: 'details', path: PATH_DASHBOARD.invoice.demoView },
      //     { title: 'create', path: PATH_DASHBOARD.invoice.new },
      //     { title: 'edit', path: PATH_DASHBOARD.invoice.demoEdit },
      //   ],
      // },
    ],
  },
];

export default navConfig;
