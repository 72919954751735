import { baseApi } from '../base';
import { buisnesResponce, createBankResponce, PageSlugResponse } from './type';

const BuisnessApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    createBuisness: build.mutation<buisnesResponce, any>({
      query: (data) => ({
        method: 'POST',
        url: `/bussiness`,
        body: data,
      }),
    }),
    createBank: build.mutation<createBankResponce, any>({
      query: (data) => {
        return {
          method: 'POST',
          url: `/bank`,
          body: data,
        };
      },
      invalidatesTags: ['connect'],
    }),
    buisnessPageSetup: build.mutation<any, any>({
      query: (data) => ({
        method: 'POST',
        url: `/page`,
        body: data.formData,
      }),
    }),
    getPageSlug: build.query<PageSlugResponse, any>({
      query: (pageSlug) => {
        return {
          method: 'GET',
          url: `/page/${pageSlug}`,
        };
      },
    }),

    pageSlug: build.mutation<any, any>({
      query: (data) => ({
        method: 'GET',
        url: `/page/${data}/username`,
      }),
    }),
  }),
});

export const {
  useCreateBuisnessMutation,
  useCreateBankMutation,
  useBuisnessPageSetupMutation,
  usePageSlugMutation,
  useGetPageSlugQuery,
} = BuisnessApi;
