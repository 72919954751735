import { baseApi } from '../base';
import { taxResponce } from './type';
const taxApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getTax: build.query<taxResponce, any>({
      query: () => '/tax',
      providesTags: ['tax'],
    }),
  }),
});

export const { useGetTaxQuery } = taxApi;
