// hooks
import { getAuth } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { useGetAuthLoginMutation } from 'src/services';
import useAuth from '../hooks/useAuth';
// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar, { Props as AvatarProps } from './Avatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: AvatarProps) {
  const [loginAuth] = useGetAuthLoginMutation();
  const { currentUser } = getAuth();

  const email = currentUser?.email;
  const [logo, setLogo] = useState<any>('');

  useEffect(() => {
    loginAuth(email)
      .unwrap()
      .then((res) => {
        const slug = res.merchant_page.pageSlug;
        const logo = res.merchant_page.logo;
        const theme = res.merchant_page.themeColor;

        setLogo(logo);
      });
  }, []);

  const { user } = useAuth();

  return (
    <Avatar
      src={user?.photoURL}
      alt={user?.displayName}
      color={user?.photoURL ? 'default' : createAvatar(user?.displayName).color}
      {...other}
    >
      {createAvatar(user?.displayName).name}
    </Avatar>
  );
}
