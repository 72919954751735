// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
import DynamicTheme from "./theme/dynamic/Theme";
// components
import ThemeSettings from './components/settings';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { onAuthStateChanged } from 'firebase/auth';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { updateToken } from './redux/slices/users';

import { useLocation, useNavigate } from 'react-router';
import { PATH_AUTH, PATH_BUISNESS } from './routes/paths';
import { AUTH } from './contexts/FirebaseContext';
import useAuth from './hooks/useAuth';
import { updateUserDetails } from './redux/slices/userDetails';
import { useGetAuthLoginMutation } from './services';

import { useSelector } from './redux/store';

// ----------------------------------------------------------------------

export default function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const business: any = useSelector(state => state.userDetailsSlice)

  const { pathname } = useLocation();

  const [loginAuth] = useGetAuthLoginMutation();

  const { logout } = useAuth();

  useEffect(() => {
    onAuthStateChanged(AUTH, async (user) => {
      if (user) {
        const token = await user.getIdToken();
        dispatch(
          updateToken({
            token: token,
          })
        );
        const authData = await loginAuth(user.email).unwrap();
        dispatch(
          updateUserDetails({
            details: authData,
          })
        );
      } else {
        dispatch(
          updateToken({
            token: null,
          })
        );
      }
    });
  }, []);

  useEffect(() => {
    onAuthStateChanged(AUTH, async (user) => {
      if (user) {
        const token = await user.getIdToken();
        const personalData = window.localStorage.getItem('userDetails');
        const pageData = window.localStorage.getItem('userPageDetails');
        if (token && user.emailVerified) {
          if (personalData) {
            const data = JSON.parse(personalData);
            if (data?.length <= 0) {
              navigate(PATH_BUISNESS.business.details, { replace: false });
            } else {
              if (pageData) {
                const pageDetails = JSON.parse(pageData);
                if (!pageDetails) {
                  navigate(PATH_BUISNESS.business.pagesetup(data?.[0]?.id), { replace: false });
                } else {
                  navigate(pathname);
                }
              } else {
                navigate(PATH_BUISNESS.business.pagesetup(data?.[0]?.id), { replace: false });
              }
            }
          } else {
            navigate(PATH_BUISNESS.business.details, { replace: false });
          }
        } else {
          if (token) {
            handleLogout();
          }
        }
      }
    });
  }, []);

  const handleLogout = async () => {
    try {
      localStorage.clear();
      dispatch(
        updateToken({
          token: null,
        })
      );
      await logout();
      navigate(PATH_AUTH.login, { replace: false });
      // window.location.reload()
    } catch (error) {
      console.error(error);
      // enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <DynamicTheme
            themeColor={business?.details?.merchant_page?.themeColor}
          >
          <NotistackProvider>
            <ProgressBarStyle />
            <ChartStyle />
            <ScrollToTop />
            <Router />
          </NotistackProvider>
          </DynamicTheme>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
