import { Card, Stack, Typography, Box, DialogTitle, CircularProgress } from '@mui/material';

import { useParams } from 'react-router';
import { useCancelCustomerPlanMutation, useFetchPlanByCustomerIdQuery, usePauseCustomerPlanMutation, useResumeCustomerPlanMutation } from 'src/services';
import Label from 'src/components/Label';
import { Grid } from '@mui/material';
import { Button } from '@mui/material';
import Iconify from 'src/components/Iconify';
import { Link } from 'react-router-dom';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useState } from 'react';
import useLocales from 'src/hooks/useLocales';
import { Dialog } from '@mui/material';
import { FormProvider, RHFCheckbox, RHFTextField } from 'src/components/hook-form';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { DialogContent } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from '@mui/material';
import { DialogActions } from '@mui/material';
import { LoadingButton } from '@mui/lab';


interface FormValuesProps {
  resumeAt: string;
  allow: boolean;
}
interface FormCancelValuesProps {
  comment?: string;
}


function CustomerPlan() {

  const { translate } = useLocales()

  const params = useParams();
  const { id } = params;

  const { data, isFetching, refetch } = useFetchPlanByCustomerIdQuery({ id });

  const [openModel, setopenModel] = useState<boolean>(false);

  const [open, setOpen] = useState<boolean>(false);

  const [openResume, setOpenResume] = useState<boolean>(false)

  const [value, setValue] = useState<any>(dayjs());

  const [merchantId, setMerchantId] = useState('')

  const [resumeId, setResumeId] = useState('')

  const [cancelPlanId, setCancelPlanId] = useState('')

  const date = dayjs(value).format('YYYY-MM-DD');

  const [pauseCustomerPlan] = usePauseCustomerPlanMutation({})

  const [cancelCustomerPlan] = useCancelCustomerPlanMutation({})

  const [resumeCustomerPlan] = useResumeCustomerPlanMutation({})


  const { enqueueSnackbar } = useSnackbar();


  const NewProductSchema = Yup.object().shape({
    // comment: Yup.string().trim().required(translate('commentIsRequire')),
  });
  const defaultValues = {
    resumeAt: '',
    allow: false

  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewProductSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = methods;

  const values = watch()

  const onSubmit = async () => {
    try {
      await pauseCustomerPlan({ merchantid: merchantId, resumeAt: date, })
        .unwrap()
        .then((res: any) => {
          reset();
          enqueueSnackbar('Plan Pause successfully');
          NewhandleClose();
          refetch()
        });
    } catch (error) {
      enqueueSnackbar(error?.data?.error, { variant: 'error' });
      reset();
      NewhandleClose();

    }
  };


  //cancel process
  const cancelSchema = Yup.object().shape({
    // comment: Yup.string().trim().required(translate('commentIsRequire')),
  });



  const cancelMethods = useForm<FormCancelValuesProps>({
    resolver: yupResolver(cancelSchema),
    defaultValues: {
      comment: '',
    },
  });

  const {
    reset: cancReset,
    handleSubmit: handleCancelSubmit,
    watch: cancelWatch,
    formState: { isSubmitting: isCancelSubmit },
  } = cancelMethods;

  const cancelValues = cancelWatch()

  const onCancelSubmit = async (data: FormCancelValuesProps) => {
    try {
      await cancelCustomerPlan({ id: cancelPlanId, data })
        .unwrap()
        .then((res: any) => {
          cancReset();
          enqueueSnackbar('Plan cancel successfully');
          handleClose();
          refetch()
        });
    } catch (error) {
      enqueueSnackbar(error?.data?.error, { variant: 'error' });
      cancReset();
      handleClose();

    }
  };


  const handleOpenModel = (id: any) => {
    setMerchantId(id)
    setopenModel(true);
  };

  const NewhandleClose = () => {
    setopenModel(false);
  };

  const handleSkip = () => {
    setopenModel(false);
  };
  const handleCancelOpenModel = (id: string) => {
    setCancelPlanId(id)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSkipCancel = () => {
    setOpen(false);
  };
  const handleResumeOpenModel = (id: string) => {
    setResumeId(id)
    setOpenResume(true);
  };

  const handleResumeClose = () => {
    setOpenResume(false);
  };

  const handleSkipResume = () => {
    setOpenResume(false);
  };


  const handleResume = async () => {
    try {
      const res = await resumeCustomerPlan(resumeId).unwrap()
      if (res) {
        enqueueSnackbar('Plan Resume successfully');
        handleResumeClose();
        refetch()
      }
    } catch (error) {
      enqueueSnackbar(error?.data?.error, { variant: 'error' });
      handleResumeClose();
    }
  }

  return (
    <>
      {isFetching ? <>
        <Stack direction="row" display="flex" justifyContent="center" alignItems="center">
          <CircularProgress size={50} sx={{ mt: 10, mb: 10 }} />
        </Stack>
      </> :
        <>
          {data &&
            data?.map((data: any, index: any) => {
              return (
                <Grid container key={index}>
                  <Grid item xs={12} md={12}>
                    <Stack spacing={3}>
                      <Card sx={{ p: 3 }}>
                        <Typography>
                          <Label color={data.status === 'succeeded' ? 'success' : data.status === 'paused' ? 'info' : 'error'}>
                            {data?.status}
                          </Label>
                        </Typography>
                        <Grid container sx={{ mt: 4 }}>
                          <Grid item md={12} xs={12}>
                            <Stack
                              direction='row'
                              display='flex'
                              justifyContent='space-between'
                              alignItems='center'
                            >

                              <Typography variant="h5">
                                {data?.plan?.name}
                              </Typography>

                              <Link style={{ textDecoration: 'none' }} to={`${PATH_DASHBOARD.customer.planview(id as string, data.plan.id)}`}>
                                <Button variant='outlined'>
                                  <Iconify icon='eva:eye-fill' sx={{ mr: 1, fontSize: 20 }} />
                                  View
                                </Button>
                              </Link>

                            </Stack>
                            <span>
                              <Typography variant="h4" color="gray">
                                {' $ ' + data?.plan.amount}
                              </Typography>
                            </span>
                          </Grid>
                        </Grid>
                        <Box
                          sx={{
                            mt: { xs: 2, sm: 0 },
                            position: { sm: 'absolute' },
                            top: { sm: 24 },
                            right: { sm: 24 },
                          }}
                        >
                          <>
                            {data?.status === 'cancelled' ?
                              <>
                              </>
                              :
                              <>
                                <Button size="small" color="error" variant="text" onClick={() => { handleCancelOpenModel(data?.id) }} sx={{ mr: 1 }}>
                                  Cancel plan
                                </Button>
                              </>
                            }
                            {data?.status === 'paused' ?
                              <>
                                <Button size="small" color="info" variant="text" sx={{ mr: 1 }} onClick={() => { handleResumeOpenModel(data?.id) }}>
                                  Resume Plan
                                </Button>
                              </>
                              :
                              <>
                              </>
                            }
                            {data?.status === 'succeeded' ?
                              <>
                                <Button size="small" color='warning' variant="outlined" onClick={() => {
                                  handleOpenModel(data?.id)
                                }
                                } sx={{ mr: 1 }}>
                                  Pause Plan
                                </Button>

                              </>
                              :
                              <> </>
                            }
                          </>


                        </Box>
                      </Card>
                    </Stack>
                  </Grid>
                </Grid>
              );
            })}
        </>
      }
      <Dialog open={openModel} onClose={NewhandleClose}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle />
          <DialogContent>
            <Stack spacing={2} sx={{ padding: 2 }}>
              <Box
                id="logo"
                component="img"
                src="/logo/logo.svg"
                sx={{ height: 50, cursor: 'pointer' }}
              />

              <RHFCheckbox name='allow' label={translate('AutoResumeText')} />

              {values.allow
                ?
                <>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      disablePast
                      renderInput={(props) => <TextField {...props} name="time-picker" />}
                      label={translate('Data & Time')}
                      value={value}
                      onChange={(newValue) => {
                        setValue(newValue);
                      }}
                    />
                  </LocalizationProvider>
                </>
                :
                <>
                </>}

            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSkip}>{translate('skip')}</Button>

            {values.allow ?
              <>
                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                  {translate('resumeAt')}
                </LoadingButton>
              </> :
              <>
                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                  {translate('Pause Now')}
                </LoadingButton>
              </>
            }

          </DialogActions>
        </FormProvider>
      </Dialog>
      <Dialog open={open} onClose={handleClose}>
        <FormProvider methods={cancelMethods} onSubmit={handleCancelSubmit(onCancelSubmit)}>
          <DialogTitle />
          <DialogContent>
            <Stack spacing={2} sx={{ padding: 2 }}>
              <Box
                id="logo"
                component="img"
                src="/logo/logo.svg"
                sx={{ height: 50, cursor: 'pointer' }}
              />
              <Typography variant='h4'>
                Are You Sure to Cancel Plan ..
              </Typography>
              <RHFTextField name='comment' label='Comment' id="comment" />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSkipCancel}>{translate('skip')}</Button>
            <LoadingButton type="submit" variant="contained" loading={isCancelSubmit}>
              {translate('Sure!')}
            </LoadingButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
      <Dialog open={openResume} onClose={handleResumeClose}>
        <DialogTitle />
        <DialogContent>
          <Stack spacing={2} sx={{ padding: 2 }}>
            <Box
              id="logo"
              component="img"
              src="/logo/logo.svg"
              sx={{ height: 50, cursor: 'pointer' }}
            />
            <Typography variant='h4'>
              Are You Sure to Resume Plan ..
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSkipResume}>{translate('skip')}</Button>
          <Button type="submit" variant="contained" onClick={() => { handleResume() }}>
            {translate('Sure!')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CustomerPlan;
