import { baseApi } from '../base';

type schedule = {
    scheduleDateTime: string;
    comment: string;
};

const customerApi = baseApi.injectEndpoints({
    endpoints: (build) => ({

        schedule: build.mutation<any, schedule>({
            query: (data) => ({
                method: 'POST',
                url: '/schedule',
                body: data,
            }),
        }),
    }),
});

export const {
    useScheduleMutation
} = customerApi;
