
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Card, Stack, Container, Typography, Button } from '@mui/material';
// routes
import { PATH_AUTH } from '../../routes/paths';
// hooks

import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';
import Logo from '../../components/Logo';
import Image from '../../components/Image';
// sections
import { LoginForm } from '../../sections/auth/login';
import useLocales from 'src/hooks/useLocales';


// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {


  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  const { translate } = useLocales()

  return (
    <Page title="Login">
      <RootStyle>
        <HeaderStyle>
          <Logo />

        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              {translate('subscriptionAppLoginTitle')}
            </Typography>
            <Image
              visibleByDefault
              disabledEffect
              src="/assets/illustrations/illustration_login.png"
              alt="login"
            />
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  {translate('signIn')}
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>{translate('enterDetailsBelowLogin')}</Typography>
              </Box>
            </Stack>

            <LoginForm />

            {smUp && (
              <><Typography variant="body2" sx={{ mt: 1, mb: 1 }}>
                {translate('dontHaveAnAccount')}
              </Typography>
                <Button
                  sx={{
                    color: "white", bgcolor: "green", '&:hover': {
                      borderColor: 'lightgreen',
                      backgroundColor: 'lightgreen',
                      color: '#000',
                    },
                  }}
                  component={RouterLink}
                  to={PATH_AUTH.register}
                  id="submit"
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {translate('getStarted')}
                </Button></>
            )}


            {!smUp && (
              <>
                <Typography variant="body2" sx={{ mt: 1, mb: 1 }}>
                  {translate('dontHaveAnAccount')}
                </Typography>
                <Button
                  sx={{
                    color: "white", bgcolor: "green", '&:hover': {
                      borderColor: 'lightgreen',
                      backgroundColor: 'lightgreen',
                      color: '#000',
                    },
                  }}
                  component={RouterLink}
                  to={PATH_AUTH.register}
                  id="submit"
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained">
                  {translate('getStarted')}
                </Button>
              </>
            )}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
