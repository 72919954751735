import { baseApi } from '../base';
import { stripConnectResponce } from './type';

const StripConnect = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getstripConnect: build.query<stripConnectResponce, any>({
      query: () => '/connect',
      providesTags: ['connect'],
    }),
  }),
});

export const { useGetstripConnectQuery } = StripConnect;
