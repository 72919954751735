// @mui
import { Grid, Stack } from '@mui/material';
import CustomerPlan from './CustomerPlan';
import ViewCustomer from './ViewCustomer';

// @types

// ----------------------------------------------------------------------



export default function ViewMainCustomer() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Stack spacing={2}>
          <CustomerPlan />
        </Stack>
      </Grid>

      <Grid item xs={12} md={6}>
        <Stack spacing={3}>
          <ViewCustomer />
        </Stack>
      </Grid>
    </Grid>
  );
}