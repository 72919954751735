import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// layouts
import DashboardLayout from '../layouts/dashboard';
import BusinessLayout from '../layouts/dashboard/BusinessIndex';

import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

import ViewCustomers from '../pages/dashboard/merchant/ViewCustomer/ViewCustomers';
import { useSelector } from 'src/redux/store';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'new-password', element: <NewPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },
    {
      path: 'business',
      element: (
        <AuthGuard>
          <BusinessLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/business/details" replace />, index: true },
        { path: 'details', element: <BussinessDetails /> },
        { path: 'pagesetup/:id', element: <PageSetup /> },
      ],
    },
    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <Dashboard /> },
        { path: 'ecommerce', element: <GeneralEcommerce /> },
        { path: 'analytics', element: <GeneralAnalytics /> },
        { path: 'banking', element: <GeneralBanking /> },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfile /> },
            { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':name/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> },
          ],
        },
        {
          path: 'invoice',
          children: [
            { element: <Navigate to="/dashboard/invoice/list" replace />, index: true },
            { path: 'list', element: <InvoiceList /> },
            { path: ':id', element: <InvoiceDetails /> },
            { path: ':id/edit', element: <InvoiceEdit /> },
            { path: 'new', element: <InvoiceCreate /> },
          ],
        },

        //Payout

        {
          path: 'payout',
          children: [
            { element: <Navigate to="/dashboard/payout/list" replace />, index: true },
            { path: 'list', element: <Payout /> },
            // { path: ':id', element: <InvoiceDetails /> },
            // { path: ':id/edit', element: <InvoiceEdit /> },
            // { path: 'new', element: <InvoiceCreate /> },
          ],
        },
        //CUSTOMER
        {
          path: 'customer',
          children: [
            { element: <Navigate to="/dashboard/customer/list" replace />, index: true },
            { path: 'list', element: <Customer /> },
            { path: 'new', element: <NewCustomer /> },
            { path: ':id', element: <ViewCustomers /> },
            { path: ':id/:id', element: <PlanDetailedView /> },
          ],
        },

        {
          path: 'plans',
          children: [
            { element: <Navigate to="/dashboard/plans/list" replace />, index: true },
            { path: 'list', element: <PlanList /> },
            { path: ':id', element: <PlanDetails /> },
            { path: 'edit/:id', element: <PlanEdit /> },
            { path: 'new', element: <PlanCreate /> },
            { path: 'phase/:id', element: <PlanPhase /> },
          ],
        },
        {
          path: 'coupon',
          children: [
            { element: <Navigate to="/dashboard/coupon/list" replace />, index: true },
            { path: 'list', element: <Coupon /> },
            { path: ':id', element: <PlanDetails /> },
            // { path: ':id', element: <EditCoupon /> },
            { path: 'new', element: <CreateCoupon /> },
          ],
        },
        //CATEGORY
        {
          path: 'setting',
          children: [
            { element: <Navigate to="/dashboard/setting/list" replace />, index: true },
            { path: 'list', element: <Setting /> },
            { path: 'privacy', element: <PrivicyPolicy /> },
          ],
        },

        {
          path: 'merchant',
          children: [
            { element: <Navigate to="/dashboard/merchant/list" replace />, index: true },
            { path: 'list', element: <MerchantList /> },
          ],
        },

        { path: 'calendar', element: <Calendar /> },
        { path: 'permission-denied', element: <PermissionDenied /> },
      ],
    },
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Agreement /> },
        { path: '403', element: <Page403 /> },
        { path: 'terms&condition', element: <TermsAndConditions /> },
        { path: 'privicyPolicy', element: <PrivicyPolicy /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      children: [
        {
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
          index: true,
        },
      ],
    },

    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// DASHBOARD

const Dashboard = Loadable(lazy(() => import('../pages/dashboard/index')));

// GENERAL
const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const PrivecyPolicy = Loadable(lazy(() => import('../pages/PrivicyPolicy')));

// ECOMMERCE

// INVOICE
const InvoiceList = Loadable(lazy(() => import('../pages/dashboard/InvoiceList')));
const InvoiceDetails = Loadable(lazy(() => import('../pages/dashboard/InvoiceDetails')));
const InvoiceCreate = Loadable(lazy(() => import('../pages/dashboard/InvoiceCreate')));
const InvoiceEdit = Loadable(lazy(() => import('../pages/dashboard/InvoiceEdit')));

//CATEGORY
// const CategoryList = Loadable(lazy(() => import('../pages/dashboard/category/Index')));

//PLANS
const PlanList = Loadable(lazy(() => import('../pages/dashboard/PlanList')));
const PlanDetails = Loadable(lazy(() => import('../pages/dashboard/PlanDetails')));
const PlanCreate = Loadable(lazy(() => import('../pages/dashboard/PLanCreate')));
const PlanEdit = Loadable(lazy(() => import('../pages/dashboard/PlanEdit')));
const PlanPhase = Loadable(lazy(() => import('../pages/dashboard/PlanPhase')));

//coupon
const Coupon = Loadable(lazy(() => import('../pages/dashboard/Coupon')));
const CreateCoupon = Loadable(lazy(() => import('../pages/dashboard/CreateCoupon')));

//CATEGORY
const MerchantList = Loadable(lazy(() => import('../pages/dashboard/merchant/Index')));
// BLOG

// Setting
const Setting = Loadable(lazy(() => import('../pages/dashboard/Setting')));
//customer
const Customer = Loadable(lazy(() => import('../pages/dashboard/Customer')));
const NewCustomer = Loadable(lazy(() => import('../pages/dashboard/NewCustomer')));
const PlanDetailedView = Loadable(lazy(() => import('../pages/dashboard/merchant/ViewCustomer/PlanDetailedView')))

//Bussiness Details
const BussinessDetails = Loadable(lazy(() => import('../pages/BussinessDetails')));

// USER
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));

// APP
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(lazy(() => import('../pages/dashboard/PermissionDenied')));

//PAGESETUP
const PageSetup = Loadable(lazy(() => import('../pages/BussinessDetails/PageSetup')));

// MAIN

const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
const Agreement = Loadable(lazy(() => import('../pages/Agreement')));
const TermsAndConditions = Loadable(lazy(() => import('../pages/TermsOfService')));
const PrivicyPolicy = Loadable(lazy(() => import('../pages/PrivicyPolicy')));

//PAYOUT
const Payout = Loadable(lazy(() => import('../pages/dashboard/payout')));
