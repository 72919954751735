import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  Grid,
  Stack,
  Divider,
  CardHeader,
  Typography,
  CardContent,
  useTheme
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { FormProvider, RHFTextField } from 'src/components/hook-form';
import { useState } from 'react';
import { useParams } from 'react-router';
import { useGetCustomerByIdQuery } from 'src/services';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Label from 'src/components/Label';
import useLocales from 'src/hooks/useLocales';
import { CircularProgress } from '@mui/material';

type FormValuesProps = {
  uid: number;
  firebaseUID: string;
  stripeID: string;
  name: string;
  phone: string;
  email: string;
  line1: string;
  city: string;
  state: string;
  postal_code: string;
  country: string;
  createdBy?: null;
  updatedBy?: null;
  status: string;
  comment?: null;
  createdAt: string;
  updatedAt: string;
};

function ViewCustomer() {

  const { translate } = useLocales();
  const params = useParams();

  const theme = useTheme()

  const { id } = params;
  const { data, isFetching } = useGetCustomerByIdQuery(id);

  const methods = useForm<FormValuesProps>({
    // resolver: yupResolver(UpdateUserSchema),
    // defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  // useMemo(() => {
  //   if (data && data) {
  //     setValue('name', data[0]?.name);
  //     setValue('phone', data[0]?.phone);
  //     setValue('email', data[0]?.email);
  //     // setValue('address', data?.line1);
  //     setValue('status', data[0]?.status);
  //   }
  //   return [];
  // }, [data]);

  const onSubmit = async (data: FormValuesProps) => { };

  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  return (
    <>

      {
        isFetching
          ?
          <Stack direction="row" display="flex" justifyContent="center" alignItems="center">
            <CircularProgress size={50} sx={{ mt: 10, mb: 10 }} />
          </Stack>
          :
          <Grid>
            <Grid item>
              <Card>
                <CardHeader
                  title={translate('customer')}
                // action={
                //   <Button
                //     onClick={handleOpen}
                //     size="large"
                //     startIcon={<Iconify icon={'eva:edit-fill'} />}
                //   >
                //     Edit Customer
                //   </Button>
                // }
                />

                <CardContent>
                  <Stack spacing={2}>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                        {translate('name')}
                      </Typography>
                      <Typography variant="subtitle1">{data?.customer?.name}</Typography>
                    </Stack>

                    <Stack direction="row" justifyContent="space-between">
                      <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                        {translate('phoneNumber')}
                      </Typography>
                      <Typography variant="subtitle1">{data?.customer?.phone}</Typography>
                    </Stack>

                    <Stack direction="row" justifyContent="space-between">
                      <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                        {translate('email')}
                      </Typography>
                      <Typography variant="subtitle1">{data?.customer?.email}</Typography>
                    </Stack>

                    <Stack direction="row" justifyContent="space-between">
                      <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                        {translate('address')}
                      </Typography>
                      <Typography variant="subtitle1">
                        {data?.customer?.line1} {data?.customer?.city} {data?.customer?.state}{' '}
                        {data?.customer?.country} {data?.customer?.postal_code}
                      </Typography>
                    </Stack>

                    <Stack direction="row" justifyContent="space-between">
                      <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                        {translate('status')}
                      </Typography>
                      <Label
                        variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                        color={
                          (data?.customer?.status === 'active' && 'success') ||
                          (data?.customer?.status === 'unpaid' && 'warning') ||
                          (data?.customer?.status === 'inactive' && 'error') ||
                          'default'
                        }
                        sx={{ textTransform: 'capitalize' }}
                      >
                        {data?.customer?.status}
                      </Label>

                    </Stack>

                    <Divider />
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
      }


      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogTitle>Edit Customer</DialogTitle>
        {/* <Divider/> */}
        <DialogContent>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Card sx={{ p: 3 }}>
                  <Box
                    sx={{
                      display: 'grid',
                      rowGap: 3,
                      columnGap: 2,
                      gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                    }}
                  >
                    <RHFTextField name="name" label="Name" InputLabelProps={{ shrink: true }} />
                    <RHFTextField name="phone" label="Phone" InputLabelProps={{ shrink: true }} />
                    <RHFTextField name="email" label="Email" InputLabelProps={{ shrink: true }} />

                    <RHFTextField
                      name="address"
                      label="Address"
                      InputLabelProps={{ shrink: true }}
                    />
                    <RHFTextField name="status" label="Status" InputLabelProps={{ shrink: true }} />
                  </Box>

                  <Stack
                    spacing={1}
                    alignItems="flex-end"
                    sx={{
                      display: 'grid',
                      columnGap: 3,
                      mt: 3,
                      gridTemplateColumns: { xs: 'repeat(2, 1fr)', sm: 'repeat(2, 1fr)' },
                    }}
                  >
                    <LoadingButton variant="contained" onClick={handleClose}>
                      Cancel
                    </LoadingButton>

                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                      Save Changes
                    </LoadingButton>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ViewCustomer;
