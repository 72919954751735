import { baseApi } from '../base';
import { merchantRequest, merchantResponce } from './type';

const merchantApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getMerchant: build.query<merchantResponce, any>({
      query: () => '/merchant',
      providesTags: ['merchant'],
    }),
    createMerchant: build.mutation<any, merchantRequest>({
      query: (data) => ({
        url: `/merchant`,
        method: 'POST',
        body: data,
      }),
    }),
    //   statusToggel: build.mutation({
    //     query: (mid) => ({
    //       method: 'PUT',
    //       url: `/merchant/${mid}`,
    //     }),
    //   }),
    //   deleteMerchant: build.mutation({
    //     query: (mid) => ({
    //       method: 'DELETE',
    //       url: `/merchant/${mid}`,
    //     }),
    //   }),
  }),
});

export const {
  useGetMerchantQuery,
  useCreateMerchantMutation,
  // useStatusToggelMutation,
  // useDeleteMerchantMutation,
} = merchantApi;
